'use client';

import React from 'react';
import Modal from '@dr-pam/common-components/Components/Modal/Modal';
import Form from '@dr-pam/common-components/Components/Form/Form';
import FormControl from '@dr-pam/common-components/Components/Form/FormControl';
import TextInput from '@dr-pam/common-components/Components/Form/TextInput';
import Button from '@dr-pam/common-components/Components/Form/Button';
import useNewsletterSignup, { NewsletterSignupValues } from '@dr-pam/common-components/Hooks/useNewsletterSignup';

export type NewsletterModalProps = {
	onClose: () => void;
};

export default function NewsletterModal({ onClose }: NewsletterModalProps) {
	const { form, handleFormSubmit, isLoading } = useNewsletterSignup();

	const handleSubmit = async (values: NewsletterSignupValues) => {
		await handleFormSubmit(values);
		onClose();
	};

	return (
		<Modal
			modalId="newsletter-signup"
			className="blur popup-overlay"
			showClose={true}
			title="Subscribe to our mailing list"
			onClose={onClose}
		>
			<div className="NewsletterSignup">
				<Form form={form} onSubmit={handleSubmit}>
					<FormControl name="name" label="Your name (optional)" disabled={isLoading}>
						<TextInput />
					</FormControl>
					<FormControl name="email" label="Your email" disabled={isLoading}>
						<TextInput type="email" />
					</FormControl>
					<Button type="submit" isLoading={isLoading} disabled={isLoading} className="large-button">
						Subscribe
					</Button>
				</Form>
				<p className="footnote">
					By clicking subscribe you agree to receive newsletters, updates and offers from Possums and Dr Pam.
					You may unsubscribe at anytime.
				</p>
			</div>
		</Modal>
	);
}

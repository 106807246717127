'use client';

import React, { useState } from 'react';
import NewsletterModal from './NewsletterModal';
import { IconMailFilled } from '@tabler/icons-react';

export default function NewsletterModalWrapper() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<a
				href="#"
				className="button quiet-large white"
				onClick={(e) => {
					e.preventDefault();
					handleOpenModal();
				}}
			>
				<IconMailFilled className="btn-mail" /> GET UPDATES
			</a>
			{isModalOpen && <NewsletterModal onClose={handleCloseModal} />}
		</>
	);
}
